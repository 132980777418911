/* App.css or your preferred CSS file */
.dropdowncontainer {
    position: relative;

    & .serviceLink {
        border-bottom: 0px solid;
        transition: var(--transitionStyleButton);
        position: relative;
        font-weight: var(--fontWeightMedium);
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
        color: var(--colorGrey700);

        flex-shrink: 0;
        height: 100%;
        padding: 0;
        margin: 0;

        & span {
            @media(max-width: 1024px) {
                font-size: 30px;
                line-height: 24px;
                position: relative;
                color: var(--marketplaceColor);
                font-weight: var(--fontWeightBold);

                &:after {
                    @media(max-width: 1024px) {
                        top: 50%;
                        content: "";
                        width: 20px;
                        height: 20px;
                        right: -25px;
                        position: absolute;
                        background-size: 20px;
                        transform: translate(0, -50%);
                        background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%200.6%200.6%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Edown_line%3C%2Ftitle%3E%3Cg%20id%3D%22%E9%A1%B5%E9%9D%A2-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Arrow%22%20transform%3D%22translate(-288)%22%3E%3Cg%20id%3D%22down_line%22%20transform%3D%22translate(288)%22%3E%3Cpath%20d%3D%22M0.6%200v0.6H0V0zM0.315%200.581l0%200%20-0.002%200.001%20-0.001%200%200%200%20-0.002%20-0.001q0%200%20-0.001%200l0%200%200%200.011%200%200.001%200%200%200.003%200.002%200%200%200%200%200.003%20-0.002%200%200%200%200%200%20-0.011q0%200%200%200m0.007%20-0.003%200%200%20-0.005%200.002%200%200%200%200%200%200.011%200%200%200%200%200.005%200.002q0%200%200.001%200l0%200%20-0.001%20-0.015q0%200%20-0.001%20-0.001m-0.018%200a0.001%200.001%200%200%200%20-0.001%200l0%200%20-0.001%200.015q0%200%200%200.001l0%200%200.005%20-0.002%200%200%200%200%200%20-0.011%200%200%200%200z%22%20id%3D%22MingCute%22%20fill-rule%3D%22nonzero%22%2F%3E%3Cpath%20d%3D%22M0.318%200.393a0.025%200.025%200%200%201%20-0.035%200L0.141%200.251A0.025%200.025%200%201%201%200.176%200.216l0.124%200.124%200.124%20-0.124a0.025%200.025%200%200%201%200.035%200.035z%22%20id%3D%22%E8%B7%AF%E5%BE%84%22%20fill%3D%22%2309244B%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
                    }
                }
            }

            @media(max-width: 768px) {
                font-size: 21px;
            }
        }

        &:hover {
            color: var(--colorBlack);
            /* border-bottom: 4px solid var(--marketplaceColor); */
            border-radius: 0;
            text-decoration: none;
        }

        @media (--viewportMedium) {
            line-height: 16px;
            margin: 0;
        }
    }

    & .serviceLinkLabel {
        display: inline-block;
        margin: 28px 0;
        text-decoration: inherit;
        text-wrap: nowrap;

        @media(max-width: 1024px) {
            margin: 0;
        }
    }
}

.megadropDown {
    background-color: #fff;
    display: grid;
    box-shadow: var(--boxShadowPopup);
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    top: 48px;
    left: -340px;
    width: 550px;
    max-height: 600px;
    overflow-y: auto;
    cursor: pointer;
    padding: 24px 0;

    @media(max-width: 1024px) {
        top: 0;
        left: 0;
        width: 100%;
        /* padding: 10px; */
        max-height: 100%;
        position: relative;
        grid-template-columns: repeat(1, 1fr);
        box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.2);
    }

    /* width */
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #d5d5d5d5;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--marketplaceColorDark);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }


    & .content {
        padding: 0 18px;

        @media(max-width: 1024px) {
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        & h3 {
            color: #000;
            font-size: 22px;
            font-weight: 700;
            line-height: normal;

            @media(max-width: 768px) {
                font-size: 16px;
                margin-top: 0;
            }
        }

        & .items {
            position: relative;
            padding-left: 14px;

            &::after {
                content: "";
                height: 5px;
                width: 5px;
                border-radius: 100%;
                position: absolute;
                left: 0;
                top: 10px;
                background-color: #000;
            }
        }
    }

}